import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Component} from "react";
import PageCoupon from "./coupon/Coupon";
import PageSuspended from "./PageSuspended";

const app_url = "http://192.168.0.21:3000"
const server_url = "http://192.168.0.21:8078";

// const app_url = "https://kupon.fempharma.hu"
// const server_url = "https://kupon.fempharma.hu/api-coupon";


class App extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {app: {app_url: app_url, server_url: server_url}, errorCode: null};
    }

    render() {
        return <Router>
            <div>
                <Switch>
                    <Route path="/" exact={true}
                           component={() => <PageSuspended app={this.state.app} user={this.state.user}/>}/>
                    {/*<Route path="/" exact={true}*/}
                    {/*       component={() => <PageCoupon app={this.state.app} user={this.state.user}/>}/>*/}
                </Switch>
            </div>
        </Router>
    }

}

export default App;
