import {Component} from "react";

class PageSuspended extends Component {

    render() {
        return <div className="Form">
            <h1>A BABÁNTÚL<sup>&reg;</sup> kuponkód akció jelenleg szünetel.</h1>
            <p className="info">
                Ha bármilyen kérdése, észrevétele van, kérjük keressen meg bennünket az <a href="mailto:info@femharma.hu">info@femharma.hu</a> e-mail címen.
            </p>
        </div>
    }

}

export default PageSuspended;