import {Component} from "react";
import './Coupon.css'
import ajax from "superagent";


class PageCoupon extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {fullName: "", email: "", mode: 0, coupon: null}
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.registerCoupon = this.registerCoupon.bind(this);
    }

    componentDidMount() {
    }


    registerCoupon() {
        ajax
            .post(this.props.app.server_url + '/coupon/request')
            .query({
                "full_name": this.state.fullName,
                "email": this.state.email
            })
            .accept('application/json')
            .then(res => {
                const content = res.body;
                this.setState({coupon: content});
            })
            .catch((err) => {

                if (err.status === 409) {
                    const error = JSON.parse(err.response.text);
                    alert(error.message);
                }
                console.log(err);
            });
    }


    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value})
    }

    render() {
        return <div className="Form">
                {this.state.coupon == null && <div>
                    <h1>BABÁNTÚL<sup>&reg;</sup> kuponregisztráció</h1>

                    <p className="info">
                        Ezen az oldalon a Babántúl<sup>&reg;</sup> vásárláshoz 15% kedvezményt biztosító kuponokat igényelheted meg.
                        Egy kuponkódot Te kapsz, egyet pedig egy olyan barátnőd / ismerősöd, akinek szintén aktuális a
                        Babántúl® szedése.
                    </p>

                    <p className="info">
                        A kuponokat az általad megadott e-mail címre küldjük, ezért ügyelj arra, hogy érvényes címet adj
                        meg.
                    </p>

                    <p className="info">
                        A regisztrációval elfogadod a <a href="https://fempharma.hu/adatvedelmi-tajekoztato-v2/"
                                                         target="_blank">Felhasználási feltételeket és adatkezelési
                        tájékoztatónkat</a>.
                    </p>


                    <label>Név:</label>
                    <input type="text" name="fullName" value={this.state.fullName} onChange={this.onPropertyChanged}/>
                    <label>Email:</label>
                    <input type="text" name="email" value={this.state.email} onChange={this.onPropertyChanged}/>
                    <button onClick={this.registerCoupon}>Regisztráció</button>
                </div>}
                {this.state.coupon != null && <div>
                    <h1>A kuponokat elküldtük Neked!</h1>
                    <p className="info">
                        A kuponokat elküldtük az általad megadott email címre: {this.state.coupon.customer.email}.
                    </p>
                    <p className="info">
                        Két e-mailt fogsz kapni. Az egyik a Te kuponkódodat tartalmazza, amit Te tudsz felhasználni a
                        Mamamibolt webshopjában.
                        A másik e-mailt kérünk küldd tovább a barátodnak/ismerősödnek, amivel ő tudja érvényesíteni a
                        saját
                        kedvezményét.
                        A levelek tartalmazzák a kupon felhasználásával kapcsolatos részleteket.
                    </p>
                    <p className="info">
                        Jó egészséget kívánunk! A <a href="http://babantul.hu">Babántúl<sup>&reg;</sup></a> csapata.
                    </p>
                </div>}
            </div>
    }
}

export default PageCoupon;